import React from 'react';
import { Route, Switch, Link, useLocation } from 'react-router-dom';
import Home from './Home/Home';
import AccountLayout from './Accounts/AccountLayout';
import AboutPage from './About/AboutPage';
import ResourcePage from './Resources/ResourcePage';

export default function Layout() {
    var curLocation = useLocation();

    const getNavClasses = (loc) =>
    {
        return curLocation.pathname === loc ? " active-nav" : " inactive-nav";
    }

    return (
        <>
            <div className='topheader' id='headerDivId'>
                <nav className="navbar navbar-dark navbar-expand-md fixed-top" style={{background: "linear-gradient(15deg, #4368a0 21%, #292791 52%, #13547a 79%, white 100%)"}}>
                    <Link className="navbar-brand" to="/">
                        <img src="/images/stockIcon.jpg" alt="Small stock icon" width="20px" height="20px"/>&nbsp;Home
                    </Link>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="nav navbar-nav">
                            <li className={"nav-item" + getNavClasses('/Accounts')}>
                                <Link className="nav-link" to="/Accounts" data-toggle="collapse" data-target="#navbarNav">Accounts</Link>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav ml-auto">
                            <li className={"nav-item" + getNavClasses('/Resources')}>
                                <Link className="nav-link" to="/Resources" data-toggle="collapse" data-target="#navbarNav">Resources</Link>
                            </li>
                            <li className={"nav-item" + getNavClasses('/About')}>
                                <Link className="nav-link" to="/About" data-toggle="collapse" data-target="#navbarNav">About Project</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <div style={{marginTop:75}}>
            </div>

            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/Accounts" component={AccountLayout}/>
                <Route exact path="/Resources" component={ResourcePage}/>
                <Route exact path="/About" component={AboutPage}/>
            </Switch>
        </>
    );
}

