import React, {useState, useEffect, useContext} from 'react';
import { StockApi } from '../../Services/StockApi';
import Format from '../../Utility/Format';
import { AppContext } from '../../AppContext';
import BootstrapTable from '../../Utility/BootstrapTable';

export default function AccountTabSummary() 
{
    const [positionList, setPositionList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { acctInfo } = useContext(AppContext);

    useEffect(() => {
        const getData = async () => {
            try {
                if (!acctInfo)
                    return;

                setLoading(true);
                var stockApi = new StockApi()
                var posList = await stockApi.getAccountPositions(acctInfo.accountId);
                setPositionList(posList);
            }
            catch (e) {
                setError(e);
            }
            finally {
                setLoading(false);
            }
        }
        getData();
    }, [acctInfo])

    function GetStatus()
    {
        if (loading)
            return <p>Data Loading...</p>;
        else if (error)
            return <p>{"Error: " + error}</p>;
        else if (!acctInfo)
            return <p>Please select account to show information.</p>
        return <></>;
    }

    const currencyFormatter = (val, row) => {
        return Format.FormatCurrency(val);
    }

    const stockValue = positionList.reduce((total, pos) => total + pos.value, 0);
    const dayChange = positionList.reduce((total, pos) => total + pos.dayChange, 0);

    function Summary()
    {
        const columns = [
            {field: 'value', title: 'Value', formatter: currencyFormatter},
            {field: 'stockValue', title: 'Stocks', formatter: currencyFormatter},
            {field: 'cash', title: 'Cash', formatter: currencyFormatter},
            {field: 'dayChange', title: 'Day Change', formatter: currencyFormatter},
            {field: 'cost', title: 'Cost Basis', formatter: currencyFormatter},
            {field: 'gain', title: 'Gain', formatter: currencyFormatter},
        ];
    
        const data = [
            { value: acctInfo.value,
              stockValue: stockValue,
              cash: acctInfo.value - stockValue,
              dayChange: dayChange,
              cost: acctInfo.cost,
              gain: acctInfo.value - acctInfo.cost}
        ]; 
    
        return (
            <>
                <h5 style={{float: 'left', marginTop: 20}}>Account Summary</h5>
                <BootstrapTable
                    columns={columns}
                    data={data}
                    showColumns={true}
                    fixedColumns={false}
                    resizable={false}
                    showToggle={true} 
                    search={false}
                    classes={'table-striped table-bordered table'}
                    theadClasses={'thead-light'}
                />
            </>
        )
    }

    function Positions()
    {
        const columns = [
            {field: 'symbol', title: 'Symbol', sortable:true },
            {field: 'name', title: 'Name', sortable:true },
            {field: 'shares', title: 'Shares', sortable:true },
            {field: 'price', title: 'Price', sortable:true, formatter: currencyFormatter},
            {field: 'dayChange', title: 'Day Change', sortable:true, formatter: currencyFormatter},
            {field: 'value', title: 'Value', sortable:true, formatter: currencyFormatter},
            {field: 'cost', title: 'Cost', sortable:true, formatter: currencyFormatter},
            {field: 'gain', title: 'Gain', sortable:true, formatter: currencyFormatter},
        ];

        const data = positionList;

        return (
            <>
                <h5 style={{float: 'left', marginTop: 20}}>Positions</h5>
                <BootstrapTable
                    columns={columns}
                    data={data}
                    showColumns={true}
                    fixedColumns={true}
                    fixedNumber={1}
                    resizable={false}
                    showToggle={true} 
                    search={false}
                    classes={'table-striped table-bordered table'}
                    theadClasses={'thead-light'}
                />
            </>

            // <table className="table table-bordered">
            //     <thead className="thead-dark">
            //         <tr>
            //             <th>Symbol</th>
            //             <th>Name</th>
            //             <th>Shares</th>
            //             <th>Price</th>
            //             <th>Day Change</th>
            //             <th>Value</th>
            //             <th>Cost</th>
            //             <th>Gain</th>
            //         </tr>
            //     </thead>
            //     <tbody>
            //         {
            //             positionList.map((pos, index) => { return (
            //                 <tr key={index}>
            //                     <td>{pos.symbol}</td>
            //                     <td>{pos.name}</td>
            //                     <td>{pos.shares}</td>
            //                     <td>{Format.FormatCurrency(pos.price)}</td>
            //                     <td>{Format.FormatCurrency(pos.dayChange)}</td>
            //                     <td>{Format.FormatCurrency(pos.value)}</td>
            //                     <td>{Format.FormatCurrency(pos.cost)}</td>
            //                     <td>{Format.FormatCurrency(pos.gain)}</td>
            //                 </tr>
            //             );})
            //         }
            //     </tbody>
            // </table>
        )
    }

    return (
        <>
            <GetStatus></GetStatus>
            {acctInfo && <Summary></Summary>}
            {acctInfo && <Positions></Positions>}
        </>
    );
}