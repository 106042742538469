import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import ResGuidelines from './ResGuidelines';
import ResSummary from './ResSummary';
import ResBrokerChoice from './ResBrokerChoice';

function ContentTable({setContent})
{
    const handleClick = param => e => {
        setContent(param);
    }

    return (
        <>
            <h3>Helpful Investing Resources</h3>
            <ul>
                <li>
                    <Button variant="link" onClick={handleClick('Guidelines')}>Stock Investing Guidelines</Button>
                </li>
                <li>
                    <Button variant="link" onClick={handleClick('Summary')}>Personal Financing and the Importance of Investing Early</Button>
                </li>
                <li>
                    <Button variant="link" onClick={handleClick('BrokerChoice')}>Choosing a Broker (Robinhood or Schwab?)</Button>
                </li>
            </ul>
        </>
    );
}

export default function ResourcePage()
{
    const [content, setContent] = useState(null);

    return (
        <>
            <div style={{marginLeft:15, marginRight:15}}>
                {!content && <ContentTable setContent={setContent}/>}
                {content && <Button variant="link" onClick={() => setContent(null)}>Back to Table</Button>}
                {content && (content === 'Guidelines') && <ResGuidelines/>}
                {content && (content === 'Summary') && <ResSummary/>}
                {content && (content === 'BrokerChoice') && <ResBrokerChoice/>}
           </div>
        </>
    );
}

