export default function ResGuidelines()
{
    return (
        <>
            <h1>
            Stock investing guidelines
            </h1>
            <h2>
            Introduction
            </h2>
            <p>
            Investing in stocks is not as complicated as many believe. &nbsp;This paper
            will give a few guidelines on how to get started. &nbsp;Before a discussion of
            how to select stocks, a few quick points are in order. &nbsp;Firstly, for the
            reader of this document, the most important decision has already been made
            – the decision to invest instead of spend. &nbsp;The decision to invest
            responsibly is far more important than the details of where the money is
            invested. &nbsp;The second point is to remember that time is on your side
            – stocks may go up or down in the short term, but over longer periods of
            time, a selection of stocks will invariably rise. &nbsp;With that said,
            let’s jump into the selection process!
            </p>
            <h2>
            Questions
            </h2>
            <p>
            The starting point for every would-be investor is to ask the right questions.
            &nbsp;Performing research on a company is largely a matter of finding the
            answers to the following questions.
            </p>
            <h3>
            Question 1: What does the company do?
            </h3>
            <p>
            The first step is to understand what the company does, and where it makes a
            profit. &nbsp;Most larger companies a composed of several divisions so
            understanding what those divisions are and what percentage of profit they are
            generating is important. &nbsp;Don’t get too mired in the details,
            instead, just have a high level understand of the top 3-5 areas of a company
            and how much they contribute to the overall profit.
            </p>
            <p>
            <strong><u>Example:&nbsp;</u></strong>
            </p>
            <p>
            For Microsoft, this might be answered as follows:
            </p>
            <p>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Office &amp;
            related: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;3.1 billion
            </p>
            <p>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;&nbsp;Azure/Cloud:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;2.4 billion
            </p>
            <p>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Personal
            computing/Xbox:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;1.5 billion
            </p>
            <p>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Total:&nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp;7 billion
            </p>
            <p>
            (This can be easily found by performing a search on: MSFT profit by division)
            </p>
            <h3>
            Question 2: What’s my confidence in the areas which this company is
            involved in?
            </h3>
            <p>
            The second question is whether you believe the primary areas in which the
            company is engaged are going to be more important, less important, or the same
            in 5-10 years. &nbsp;This is one of the most important factors in whether to
            invest in a company. &nbsp;If you are confident that a sector or industry will
            grow, time is on your side and eventually your investment should pay off.
            </p>
            <p>
            <strong><u>Example:</u></strong>
            </p>
            <p>
            Lee enterprises makes most of their money from selling jeans. &nbsp;I’m
            confident that over the next ten years the number of jeans sold will go up for
            the following reasons:
            </p>
            <ol>
            <li>
            More people are dressing casual. &nbsp;In my church, while some of the older
            folks still wear suits, the majority wear jeans. &nbsp; I think this trend
            will continue.
            </li>
            <li>
            Other countries in Asia and Europe are imitating US styles which should also
            increase the demand for jeans.
            </li>
            </ol>
            <h3>
            Question 3: Is the company expensive or cheap?
            </h3>
            <p>
            Although you can make money buying good companies at expensive prices,
            it’s advisable to look for companies at more reasonable prices. &nbsp;If
            a company is selling at an expensive price, it gives you very little margin of
            error. &nbsp;If the company has a good, but not great quarter, the stock price
            may drop because the expectations are very high.
            </p>
            <p>
            The primary way to determine whether a company is expensive or cheap is the
            Price – Earnings ratio (or PE). &nbsp; This is computed by dividing the
            price per share by the amount of profit earned per share. &nbsp;Another way to
            think of this number is the amount of stock you’d have to buy to
            generate $1 in profit. &nbsp;Generally, the range of around 15 is a reasonable
            PE, although in a growth stock, this might reach 20 or 25. &nbsp;In a few
            cases, you might see a negative PE. &nbsp;This means the company is losing
            money (e.g. a PE of -5 means that if you bought $5 of stock, each year
            you’d lose $1 in earnings). &nbsp;Generally, you should avoid companies
            which are currently losing money unless there are extraordinary circumstances.
            </p>
            <p>
            <strong><u>Example:</u></strong>
            </p>
            <p>
            I love the company Netflix. &nbsp; I believe that over the next ten years more
            people will stop subscribing to cable and choose online streaming for their
            entertainment. &nbsp;Netflix makes 90% of its profit from online streaming of
            content. &nbsp;Currently, however, the PE of Netflix is 342 (it would cost me
            $342 to generate $1 of profit). &nbsp;While there are some analysts who
            believe that in 3 years, Netflix’s PE will be around 44 (still a high
            number!), this is too risky. &nbsp;If Netflix has even a moderately bad
            quarter, the stock could easily drop by 50%. &nbsp;Although I like the general
            company, it’s too expensive for me to invest in currently.
            </p>
            <h3>
            Question 4: How does the company stack up against its competitors?
            </h3>
            <p>
            After the other questions have been answered, this is the last question which
            should be researched. &nbsp; This is basically determining whether there are
            other major competitors in the same business and how this company compares to
            those companies.
            </p>
            <p>
            Although investors sometimes mistakenly start with this question, this is fair
            less important than the other questions listed. &nbsp;As an example, suppose
            that I determine that I like Miramax movies far more than Paramount movies and
            decide to invest in Miramax. &nbsp;If the movie industry is going up over the
            next decade, it’s likely that both Paramount and Miramax will do very
            well. &nbsp;Similarly, if Miramax is very expensive and Paramount is
            relatively cheap, it’s likely that investing in Paramount will do better
            even if Miramax currently makes better movies.
            </p>
            <p>
            <strong><u>Example:</u></strong>
            </p>
            <p>
            I think running shoes will do well over the next 10 years. &nbsp;More people
            interested in healthy lifestyles and jogging will continue to grow in
            popularity. &nbsp;Additionally, more people are aware of the need of good
            running shoes to reduce the stress on their joints. &nbsp;Both Asics and
            Adidas make most of their money from running shoes and both are priced in a
            reasonable range. &nbsp;I’ve had experience with Asics and think that
            they make a better shoe. &nbsp;Additionally, I looked on both of their
            websites, and I found the ordering process with Asics much more
            straightforward than that of Adidas. &nbsp; Based on this, I’m choosing
            to invest in Asics.
            </p>
            <h2>
            Conclusion
            </h2>
            <p>
            Hopefully, this gives some useful guidelines on how to select stocks for
            investment purposes. &nbsp;A famous investor once said the following:
            “The stock market facilitates a transfer of money from people who
            don’t ask reasonable questions to those who do”. &nbsp;The last
            piece of advice is to trust your judgement – much of the process
            involves making long term predictions. &nbsp;I believe that often younger
            people looking with fresh eyes can have as much or more success as those with
            experience. &nbsp;With that in mind, Happy Investing!
            </p>
            <p>
            </p>
        </>
    );
}
