
class Format {
    static FormatCurrency(num)
    {
        return new Intl.NumberFormat('en-US',
        { style: 'currency', currency: 'USD' } ).format(num); // '$100.00'        
    }

    static FormatPercent(num, precision = 2)
    {
        return (num * 100).toFixed(precision)+"%"
    }

    static FormatDate(dateString)
    {
        let newDate = new Date(dateString);
        return (newDate.getMonth() + 1) + "/" + newDate.getDate() + "/" + newDate.getFullYear();
    }
}

export default Format;
