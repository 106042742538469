import React from 'react'
import ReactDOM from 'react-dom'
import $ from 'jquery';
import 'bootstrap-table'
import 'bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns'
import 'bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns.css'
import 'popper.js/dist/popper.min.js'

export default class BootstrapTable extends React.Component {
  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).bootstrapTable(this.props)
    // const script = document.createElement("script");
    // script.src = "/bootstrap-table-ext/fixed-columns/bootstrap-table-fixed-columns.min.js"
    // script.async = true;
    // document.body.appendChild(script);
  }

  componentWillReceiveProps(nextProps) {
    $(ReactDOM.findDOMNode(this)).bootstrapTable('refreshOptions', nextProps)
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillUnmount() {
    $(ReactDOM.findDOMNode(this)).bootstrapTable('destroy')
  }
  
  render() {
    return (
      <table />
    )
  }
}
