import React, {useContext, useState, useEffect} from 'react';
import { StockApi } from '../../Services/StockApi';
import { AppContext } from '../../AppContext';
import AccountDetail from './AccountDetail';

export default function AccountLayout()
{
    const [acctList, setAcctList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { acctInfo, setAcctInfo } = useContext(AppContext);

    useEffect(() => {
        const getData = async () => {
            try {
                setError(null);
                setLoading(true);
                var stockApi = new StockApi()
                var acctList = await stockApi.getAllAccounts();
                setAcctList(acctList);
            }
            catch (e) {
                setError(e);
            }
            finally {
                setLoading(false);
            }
        }

        getData();
    }, [])

    function GetStatus()
    {
        if (loading)
            return <p>Data Loading...</p>;
        else if (error)
            return <p>{"Error: " + error}</p>;
        else return <></>;
    }

    function AccountSelectList()
    {
        if (acctList == null)
        {
            return(<></>);
        }
        
        const handleChange = (event) => {
            if (event.target.value === 0)
                setAcctInfo(null);
            else {
                var account = acctList.find((acct)=> acct.accountId.toString() === event.target.value.toString());
                setAcctInfo(account);
            }
        }

        return (
            <>
                <label>Select Account:</label>
                <select value={acctInfo ? acctInfo.accountId : 0} onChange={handleChange}>
                    <option value={0} key={0}>All Accounts</option>
                    {acctList.map((acct,index) => 
                        {
                            return(<option value={acct.accountId} key={index}>{acct.firstName + ' ' + acct.lastName}</option>);
                        })};
                </select>
            </>
        );
    }

    return (
        <>
            <GetStatus></GetStatus>
            <AccountSelectList></AccountSelectList>
            {acctList && <AccountDetail acctList={acctList} setAccountDetail={setAcctInfo}></AccountDetail>}
        </>
    );
}