import React, {useState, useEffect, useContext} from 'react';
import { StockApi } from '../../Services/StockApi';
import Format from '../../Utility/Format';
import { AppContext } from '../../AppContext';
import '../../../node_modules/react-vis/dist/style.css';
import {
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    LineSeries, 
    DiscreteColorLegend,
  } from 'react-vis';
import 'react-virtualized/styles.css'; // only needs to be imported once
import {AutoSizer} from 'react-virtualized';

export default function AccountTabChart() 
{
    const [histData, setHistData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dataLegend, setDataLegend] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [legendUpdateCount, setLegendUpdateCount] = useState(0);
    const [chartTimePeriod, setChartTimePeriod] = useState('1');
    const { acctInfo } = useContext(AppContext);

    useEffect(() => {
        const getData = async () => {
            try {
                if (!acctInfo)
                    return;

                setLoading(true);
                setError(null);
                var stockApi = new StockApi();
                console.log('Getting data...');
                var tempResults = await stockApi.getHistoricalDataForAccount(acctInfo.accountId);

                var historicalData = [], dataLegendArray = [];

                // Convert Data
                for (let i = 0; i < tempResults.length; i++)
                {
                    if (tempResults[i].quotes.length === 0)
                    {
                        console.log("Found empty array for symbol " + tempResults[i].symbol);
                        continue;
                    }
                    
                    var dataSeries = tempResults[i].quotes.map(quote => ({x: Date.parse(quote.date), y: quote.adjustedClose}));
                    historicalData.push(dataSeries);
                    dataLegendArray.push({symbol: tempResults[i].symbol, enabled: true});
                }
                setHistData(historicalData);
                setDataLegend(dataLegendArray);
                GenerateFilteredData(1, historicalData);
            }
            catch (e) {
                setError(e);
            }
            finally {
                setLoading(false);
            }
        };
        getData();
    }, [acctInfo])

    // Generate filtered data
    const GenerateFilteredData = (timePeriod, data) => {
        let startDate = new Date();
        switch (timePeriod) {
            case "1":
                // Lifetime of account - set to small value
                startDate = new Date("2000-01-01");
                break;
            case "2":
                // Year to date - set to Jan 1st
                startDate.setMonth(0);
                startDate.setDate(1);
                break;
            case "3":
                // Last 6 months
                startDate.setMonth(startDate.getMonth() - 6);
                break;
            case "4":
                // Last 2 years
                startDate.setFullYear(startDate.getFullYear() - 2);
                break;
            default:
                startDate = new Date("2000-01-01");
                break;
        }
        console.log("GenerateFilteredData: " + startDate);

        var newData = []
        for (var i=0; i < data.length; i++)
        {
            let tempData = data[i].filter(point => point.x >= startDate);
            if (tempData.length === 0)
            {
                // If no data after filtering, select initial data point
                tempData.push({x: startDate, y: data[i][0].y})
            }
            let startPrice = tempData[0].y;
            newData[i] = tempData.map(point => ({x: point.x, y: (point.y - startPrice) / startPrice }));
        }
        setFilteredData(newData);
    }

    const legendClick = (value, index) => 
    {
        var tempData = dataLegend;
        tempData[index].enabled = !tempData[index].enabled;
        setDataLegend(tempData);
        GenerateFilteredData(chartTimePeriod, histData);
        setLegendUpdateCount(legendUpdateCount + 1);
    }

    const timeOptions = [
        {value : '1', label: 'Lifetime of Account'},
        {value : '2', label: 'Year to Date'},
        {value : '3', label: 'Last 6 Months'},
        {value : '4', label: 'Last 2 Years'},
    ];

    const changeTimePeriod = (event) => 
    {
        setChartTimePeriod(event.target.value); 
        GenerateFilteredData(event.target.value, histData);
        setLegendUpdateCount(legendUpdateCount + 1);
    }

    const TimePeriodSelect = () => {
        return (
            <>
                <label style={{color:'black'}}><b>Charting Time Period:&nbsp;</b></label>
                <select onChange={changeTimePeriod} value={chartTimePeriod}>
                        {timeOptions.map((option, index) => 
                            (<option value={option.value} key={index}>{option.label}</option>
                    ))}
                </select> 
            </>
        );
    }

    const RenderChart = React.memo(({data, legends}) => {
        if (data && legends && (data.length === legends.length))
            return (
                <>
                    <AutoSizer>
                        {({height, width}) => {
                            // Try to find a reasonable height based on the width and height of the current window
                            var newHeight = window.innerHeight * .7;
                            if (newHeight > width/2)
                                newHeight = width/2;
                            return (
                                <XYPlot width={width} height={newHeight}>
                                    <VerticalGridLines />
                                    <HorizontalGridLines />
                                    <XAxis title="Date" tickTotal={8} tickFormat={v => Format.FormatDate(v)}/>
                                    <YAxis title="Stock Price" tickFormat={v => Format.FormatPercent(v, 0)}/>
                                    {data.map((series, index) => (
                                        <LineSeries data={series} key={index} opacity={legends[index].enabled ? 1 : 0}/>
                                    ))}
                                    <DiscreteColorLegend items={legends.map(legend => (legend.enabled ? "☒" : "☐") + legend.symbol)} orientation={'horizontal'} onItemClick={legendClick}>
                                    </DiscreteColorLegend>
                                    <TimePeriodSelect></TimePeriodSelect>
                                </XYPlot>
                            );
                        }}
                    </AutoSizer>
                </>
            )
        else return (<></>);
    }, [legendUpdateCount]);

    function GetStatus()
    {
        if (loading)
            return <p>Data Loading...</p>;
        else if (error)
            return <p>{"Error: " + error}</p>;
        else if (!acctInfo)
            return <p>Please select account to show information.</p>
        return <></>;
    }

    return (
        <>
            {acctInfo && <h3>Stock Performance Charts for {acctInfo.firstName}</h3>}
            <GetStatus></GetStatus>
            <RenderChart data={filteredData} legends={dataLegend}></RenderChart>
        </>
    );
}