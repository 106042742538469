
export default function Home()
{
    return (
        <div className="container">
            <div className="d-md-flex justify-content-md-center">
                <img className="img-fluid" src="/images/Geraldine-Kemi.jpg" alt="Family" style={{maxWidth: "100%", height: "auto"}}/>
            </div>
            <div className="about-me">
                <p>Welcome to the Stock Project Website!&nbsp; This shows the stock portfolios of the various children, nieces, and nephews of the Welch family who have managed investments for Joe and Tami Welch.</p>
                <p>Over the years, Joe and Tami have decided to utilize crowd-sourcing (aka child labor) to help them manage their savings investments.&nbsp; Joe would give a financial workshop to the participants, discussing investments and the principles of stock selection.&nbsp; The participant would then get to chose between a set of 7 stock choices to invest from.&nbsp; Part of Joe and Tami's investments would then be invested in those stocks and the participant would manage the investment going forward.</p>
                <img className="img-fluid" src="/images/Joe-Tami.jpg" alt="Joe/Tami" style={{maxWidth: "100%", height: "auto"}}/>
                <p>Happily, the results have worked out well on both sides - our children, nieces and nephews have learned a lot and our investments have flourished.&nbsp; Additionally, Joe and Tami's investments have benefited from getting some younger thinking brought into the mix (for example, they're just coming around to believe that Amazon might not be a flash in the pan).&nbsp; &nbsp;We hope you enjoy the site!</p>
            </div>
        </div>
    );
}
