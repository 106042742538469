export default function ResSummary()
{
    return (
        <>
        <h1>
            Personal Finance and the Importance of Investing Early
        </h1>
        <p>
            &nbsp;
        </p>
        <h2>
            Introduction
        </h2>
        <p>
            This sheet gives some of my thoughts and guiding principles around
            investing and saving. &nbsp;While this is primarily aimed at new
            investors, it would likely also contain helpful advice for experienced
            investors.
        </p>
        <p>
            The document is organized into the following 5 sections:
        </p>
        <ul>
            <li>Invest early</li>
            <li>Save first</li>
            <li>Progress by the inch</li>
            <li>ABCs of investing</li>
            <li>Monitoring the investments</li>
        </ul>
        <h2>
            Section 1: Invest Early
        </h2>
        <p>
            This section discusses the importance (and benefits) of starting investing
            as early as possible.
        </p>
        <p>
            One of my favorite proverbs is the following Chinese Proverb: “The Best
            Time To Plant a Tree Was 20 Years Ago, The Second Best Time Is Now”.
            &nbsp;In the world of investing, starting as early as possible is
            important towards reaping the full benefits. &nbsp;
        </p>
        <p>
            To illustrate this, I sometimes give the following quiz to investors:
        </p>
        <p>
            “Fred starts investing $100 a month at the age of 20 and continues this
            for 15 years. &nbsp;At the age of 35, he stops investing.
        </p>
        <p>
            On the other hand, Jim waits until he is more settled and begins investing
            at the age of 35, but he invests $200 a month. &nbsp;Jim keeps on saving
            and investing for the rest of his life, continuing the $200 a month
            contribution to his investments.
        </p>
        <p>
            The question is how long will it take Jim’s investments to match Fred’s
            investments?”
        </p>
        <p>
            While this is dependent on the interest rate, many people answer that
            perhaps it will take Jim an extra 10 years to match Fred – Jim is
            contributing twice as much per month, but Fred started earlier. &nbsp;
        </p>
        <p>
            Surprisingly, the answer is that Jim will never catch Fred because by the
            time that Jim starts investing, Fred will be making more on his interest
            than Jim is contributing. &nbsp;We’ve all heard of the fable of the
            Tortoise and the Hare in which the Tortoise will eventually catch the Hare
            given enough time and patience. &nbsp;In the world of investing, however,
            starting early is of key importance and can have lasting benefits even
            after you stop actively contributing to your investments.
        </p>
        <h2>
            Section 2: Save First
        </h2>
        <p>
            A second guiding principle which I often talk with people about is the
            concept of saving (and investing) first, and spending second. &nbsp;Many
            people have a goal to save and invest and make honest efforts to manage
            their spending in order to make this possible, but fall short due to money
            pressures. &nbsp; This can be a touchy topic because people who are unable
            to save are often already making sacrifices to cover necessary expenses.
        </p>
        <p>
            One of my recommendations is to turn the thinking around and ask the
            question – “If my job paid me, say, $100 less per month, would I need to
            quit and find a job which paid more?” &nbsp; This is the essence of
            thinking in the “Save First” framework. &nbsp;Each month, the savings
            amount is set and budgeting is done on the remaining money. &nbsp; This
            may require a further downsizing of monthly expenses, but this approach
            will ensure that the longer term savings and investing goals will have
            priority.
        </p>
        <p>
            As part of this approach, the money which goes into the savings and
            investing should be separate from a house maintenance or “rainy day” fund.
            &nbsp;Some of the people have I’ve advised in the past have setup an
            investment/savings account, but broken it when they bought a house.
            &nbsp;While having an account setup to save for a house is a fine idea,
            this is not the same as a “Life Investing Fund” which will ensure that
            later in life you will be able to make life choices which are not purely
            dependent on financial considerations.
        </p>
        <p>
            One note to this principle is that the first priority should be to pay off
            any existing high cost debt, particularly with respect to credit cards or
            high interest car/house loans. &nbsp; Those in this unfortunate situation
            may benefit from professional financial guidance to help setup plans to
            either pay off or restructure this debt.
        </p>
        <h2>
            Section 3: Progress By The Inch
        </h2>
        <p>
            During family discussions around saving and investing, one of my favorite
            uncles will often refer to the following piece of advice: “Progress by the
            yard is hard, progress by the inch is a cinch”. &nbsp;This helps serve as
            a useful reminder that the best way to achieve success in investing is to
            create a sound, diversified portfolio and allow it to grow in time. &nbsp;
            In some respects it is similar to a casino which may lose money temporary
            due to a gambler’s hot streak, but will eventually come out ahead because
            the odds are in the house’s favor. &nbsp; Investors may have some bad
            years but over time they will end up ahead if they invest in a reasonable
            manner.
        </p>
        <p>
            While this guidance may seem obvious, I have seen a variety of examples of
            well-intentioned investors who make mistakes in this area. &nbsp;Some of
            the common issues which I have seen include:
        </p>
        <ul>
            <li>
            Trading too often, sometimes buying and selling individual stocks on a
            weekly or monthly basis
            </li>
            <li>
            Over reacting to downturns, e.g. shifting money out of the stock market
            after one or two down years
            </li>
            <li>
            Investing in a few “hot” stocks recommended by news articles (or even
            worse – cable financial pundits) instead of keeping the money in a
            diversified fund
            </li>
        </ul>
        <p>
            I have attempted to understand why people fall into these mistakes,
            particularly since some of these people understand the theory behind
            investing. &nbsp;One of my conclusions is that at the root of many of
            these mistakes are two emotions – fear and pride. &nbsp;Pride can cause
            people to try to “beat the market”, which often results in decisions which
            are more closely related to gambling than long term investing. &nbsp;Fear
            can cause people to act in short term ways, for example shifting money out
            of the stock market after a large downturn which can effectively end up
            with the investor “buying high and selling low”.
        </p>
        <h2>
            Section 4: ABCs of Investing
        </h2>
        <p>
            This section will focus on the practical aspects of creating an initial
            investment account and getting started investing.
        </p>
        <p>
            There are several companies which allow you to invest, but my personal
            recommendation is Charles Schwab. &nbsp; Some of the reasons which I
            recommend Charles Schwab are:
        </p>
        <ul>
            <li>
            Good access across the US, with excellent options for web, phone, or
            walk-in access in all states
            </li>
            <li>
            Have offerings very similar to a bank (e.g. credit cards, check writing,
            interest) with the extra ability to buy/sell stocks and mutual funds
            </li>
            <li>
            Have negotiated with a variety of mutual funds to eliminate or lower the
            fees needed to buy into the fund (they can do this because of their
            size)
            </li>
            <li>
            Focus on serving as a stock “bank” instead of providing stock guidance
            for their customers. &nbsp; Other companies sometimes can blur this
            boundary and provide guidance for users to invest in funds which have
            high management fees. &nbsp;Many banks do this (and generally these are
            not the best investments).
            </li>
        </ul>
        <p>
            A second consideration might be to create two separate personal accounts,
            one for a long term “Life Investing Fund” (as outlined in Section 2) and
            another for investing with a shorter time horizon, e.g. with the goal of
            paying for college or buying a house. &nbsp; There is no extra cost to
            creating two accounts and this can be beneficial in helping to keep track
            of your accounting. &nbsp;I’ve used this in the past as a way to
            facilitate discussions about whether money should be set aside for a
            specific purpose or whether it should be part of my longer term
            investments.
        </p>
        <p>
            In this entire document on investing, this is the only paragraph which
            discusses a specific investment option. &nbsp;This is by design – much of
            what I call investing is really about saving and having the right long
            term approach to growing money. &nbsp;For portfolios of under $100,000
            which are going to be invested for a period longer than 10 years, the
            recommended advice is to invest all money into an index fund which tracks
            the general market, namely Schwab Total Stock Market Index Fund (the stock
            symbol is SWTSX). &nbsp;The Schwab funds generally have low management
            fees as well as low trading fees if bought from a Schwab account. &nbsp;In
            addition to the Schwab set of funds, Vanguard is also a widely respected
            set of funds. &nbsp;Once the investment portfolio grows to the point where
            it makes sense to add international and income generating stocks, Vanguard
            offers some solid alternatives to the Schwab set of funds.
        </p>
        <p>
            Although this advice may seem overly simplistic given the amount of
            technical terminology used when people analyze or talk about stocks, but
            roughly 70% of professional stock managers will underperform this index
            fund (or the appropriate index fund in their area) over a series of years.
            &nbsp;This means that many professional money managers are charging money
            but are employing strategies worse than investing in an index fund such as
            SWTSX. &nbsp;Even those managers who consistently beat the appropriate
            index fund generally only do so by a small margin (e.g. less than 1% on
            average). &nbsp;In part, this helps explain why some of the discussions in
            the media around investing can seem complicated – many of the managers in
            the financial industry purposefully use language and technical jargon to
            confuse would be investors and justify their fees. &nbsp;For portfolios
            over $100,000, I would recommend employing a financial planner to help
            perform an initial allocation of the money to ensure the money is
            diversified across US stocks, bonds, and international holdings; but even
            this activity should generally would not need to be performed on a yearly
            basis.
        </p>
        <h2>
            Section 5: Monitoring the Investments
        </h2>
        <p>
            For those who have the followed the guidelines laid out in this document,
            the final question remains as to how often the investments should be
            checked and changes made. &nbsp;Generally, my recommendation would be to
            check the investments on a 3 or 6 month basis. &nbsp;This should be enough
            time to see some significant growth and make any changes, as necessary, to
            the investment allocation. &nbsp;Seeing the effects of the money actively
            working on your behalf in the investment account can help validate some of
            the decisions you’ve made in order to create a savings/investing plan.
        </p>
        <p>
            &nbsp;
        </p>
        <p>
            Hopefully this document has been helpful! &nbsp;Happy investing!
        </p>
        </>
    );
}
