import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import { AppContext } from './AppContext'
import React from 'react'
import Layout from './Views/Layout'
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  const [acctInfo, setAcctInfo] = React.useState(null);

  return (
    <Router>
      <AppContext.Provider value={{ acctInfo, setAcctInfo }}>
        <Layout/>
      </AppContext.Provider>
    </Router>
  );
}

export default App;
