export default function AboutPage()
{
    return (
        <>
            <div style={{marginLeft:15, marginRight:15}}>
            <h2>Overview</h2>
            <p>This project was written in .NET and hosted on Azure.&nbsp; While this provides functionality for the stock project participants, much of the motivation behind the project was for me to get hands-on learning for some of the technologies involved.</p>
            <p>The technology stack can be divided into the following areas:</p>
            <ul>
                <li>Design/Layout</li>
                <li>Web Application</li>
                <li>Backend Services</li>
                <li>Database</li>
                <li>Stock Providers</li>
                <li>Hosting Environment</li>
            </ul>
            <p>Each of these areas is described in further detail below (along with some of my learnings and observations).</p>
            <h2>Design/Layout</h2>
            <p>Initially, this whole phase was skipped.&nbsp; While the resulting product was functional, it was clunky to use and was only minimally responsive (i.e. it required large amounts of scrolling on the phone).&nbsp; As a result, I ended up buying BootStrap Studio and laying out an initial design.&nbsp; Most of the key forms were prototyped and I got feedback from my stepson before implementing the designs.&nbsp; While the design modified slightly as I progressed with my second implementation, overall, things went fairly smoothly as I could focus more fully on coding the web app.&nbsp; Learning BootStrap Studio took an initial investment of time, but once learned, vastly simplified the process of laying out the pages and making use of Bootstrap.</p>
            <p>Overall, I'm sold on creating a layout in a product like Bootstrap Studio, even for relatively small project.</p>
            <h2>Web Application</h2>
            <p>The web application was built as a single page application in React.&nbsp; The key libraries and frameworks chosen include:</p>
            <ul>
                <li>React, Bootstrap, React-Router-Dom - Basic React and Routing Libraries</li>
                <li>React-Bootstrap - Bootstrap wrapper for use in React</li>
                <li>React-Vis - Charting package for showing stock charts (written by Uber)</li>
            </ul>
            <p>The state was managed via react hooks and context, along with some use of browser session storage.&nbsp; Although I started my react development using Redux (a global state store), I now find this overkill on small to medium sized projects.</p>
            <h2>Backend Services</h2>
            <p>The backend services were written in C#, using AspCore.Net.  The data access was entirely written using Entity Framework Core (EFCore).  Overall, I was happy with the process of using these technologies.  Additionally, EFCore 6.0 has several features which help increase the developer's productivity - one of which is easy setup of the logging of all calls to the database.  This one line addition enabled me to see how EFCore was interfacing with the DB and ensure that the proper amount of data was getting retrieved.  One issue with using entity layers such as JOOQ, Hibernate or EFCore is that they can hide where data operations are occurring.  Having a view as to the DB commands which were generated was extremely useful in ensuring set operations always occurred in the DB.</p>
            <p>The API was exposed via REST, primarily for simplicity.&nbsp; It may well make sense to expose the data as GraphQL in the future.&nbsp; This would allow more flexibility within the Web App without risking a chatty interface.&nbsp; That said, some of the possible cons related to moving to GraphQL include:</p>
            <ul>
                <li>.NET version not mature</li>
                <li>Higher complexity in debugging and testing on server side (free-form queries)</li>
                <li>Reduced caching (all requests come as HTTP Posts)</li>
            </ul>
            <h2>Database</h2>
            <p>The Database was written on PostgresQL, largely based on cost considerations.  The schema was relatively straightforward (and small).  There are tables allowing for cached stock market pricing data.</p>
            <p>As with most of my projects, I've attempted to treat the database as a commodity and avoid having database vendor specific aspects leak into my application code.&nbsp; Additionally, I developed this using a "Database first" design as opposed to a "Code first" design methodology.&nbsp; I feel that the database schema is very important and generally should be optimized.&nbsp; Once this is done, I prefer to work with the entity layer to have it adapt to the database schema instead of allowing the entity layer to "own" the schema.</p>
            <h2>Stock Providers</h2>
            <p>Since Yahoo shut down their free stock quote services, getting reliable stock data has been a challenge.  Towards that end, I ended up choosing two providers: IEX Cloud and Alpha Vantage.  IEX Cloud provides great quotes (15 mins delayed) and has a reasonable free plan which covers the bulk of stock pricing information which this app requires.  For the charting view, I used Alpha Vantage which has a free plan which allows the ability to get 20 years of historical stock data.</p>
            <p>Although this app is currently using the free plans on both these services, the next level plan is not expensive and would be a good option if this were to grow.</p>
            <p></p>
            <h2>Hosting Environment</h2>
            <p>The application (both front-end and backend) and database are hosted on Azure.&nbsp; This was fairly easy to setup and runs at about $50 a month for hosting costs (database = $35, front-end/back-end = $15).</p>
            <p>The database chosen was a hosted version of PostgresQL.&nbsp; The backend services are hosted as a web app.&nbsp; Azure has in preview a service which allows for static web content (such as a single page react act) to be hosted for free, along as it is stored in GitHub.&nbsp; All of the services have built in CI pipelines which enable check-ins to the master branch to be automatically built and deployed by Azure.&nbsp; Overall, the process was very straightforward and easy to setup and troubleshoot.</p>
            <p></p>
            <h2>Conclusion</h2>
            <p>This was a fun project!&nbsp; Some possible enhancements which I may add include:</p>
            <p></p>
            <ul>
                <li>Making the app more responsive (e.g. in small device sizes, collapse tables into columnar displays)</li>
                <li>Moving from REST Api to GraphQL</li>
                <li>Adding more functionality (e.g. adding dividends to the overall stock performance calculations)</li>
            </ul>
            <p>For more information, comments, or suggestions, please send email to: JoeWelch@msn.com.&nbsp; Thanks for reading!</p>
            </div>
        </>
    );
}