const ResBrokerChoice = () => (
    <>
      <h1>Choosing a Broker</h1>
      <h2>Introduction</h2>
      <p>
        In order to get started investing, one of the first choices which the
        prospective investor must make is to choose a broker (or company which
        will execute the trades). Some of the considerations include the
        following:
      </p>
      <ul>
        <li>
          Do I want investment advice (which I'll pay for) or am I willing to
          self-direct my investments?
        </li>
        <li>
          Is a minimum such as $1,000 initial deposit an issue, or do I want to
          start with a lower amount and build up to that level?
        </li>
        <li>
          Is this purely an investment account, or do I want banking features such
          as the check writing and debit cards against my account?
        </li>
      </ul>
      <p>
        This document generally assumes that the investor is focused on
        self-investing the money, which would be my recommendation. Some of the
        reasons why I recommend this approach are the following:
      </p>
      <ul>
        <li>
          Many advisors have "hidden incentives" which can be difficult to
          determine. For example, some Fidelity Advisors are paid relatively low
          amounts to manage your money, but make extra when you invest in Fidelity
          Mutual funds. This essentially causes a conflict of interest - many of
          the Fidelity Funds are good, but contain high management costs.
        </li>
        <li>
          Starting investing can be very easy - pick a Vanguard mutual fund (which
          generally are very high quality and have low management fees) and select
          whether you're interested in tracking the market (index fund) or general
          growth.
        </li>
        <li>
          Most professional investment advisors fail to beat the market, meaning
          that beginning investors end up paying for advice which causes them to
          underperform the market. I'd recommend a professional manager only after
          you've built up enough resources that you need diversification.
        </li>
      </ul>
      <p>
        For these reasons, I would generally prefer choosing Charles Schwab,
        ETrade, or Robinhood over a more "full-service" broker such as Fidelity or
        Prudential.
      </p>
      <h2>Is Robinhood a Good Choice?</h2>
      <p>
        Robinhood has many advantages over a larger broker and is targetted at
        younger investors in many ways. There is no account minimum, no
        transactions fees when buying or selling stocks, and offers nice models
        for saving small amounts (e.g. $5 per month) which can be investing in
        fractional shares of stocks.
      </p>
      <p>
        With that in mind, I have several concerns regarding Robinhood, especially
        as your investments grow:
      </p>
      <ul>
        <li>
          Robinhood sells your trade data, allowing you to be targetted for online
          attacks. While this is part of their business model, it makes it easy
          for hackers to identify you as a target.
        </li>
        <li>
          Robinhood gets kickbacks from the brokerage who is processing
          your order. This may sound complicated, but when you purchase
          a stock (say $500 worth of Microsoft), most brokers will execute this in
          such a manner that you buy it at the lowest possible price. For this
          trade, they will charge an amount, say $10, as a commission for their
          work. Robinhood will execute your order with the broker who pays them
          the most, sometimes causing you to buy the stock at a higher price. This
          could well cost you more than $10 in the price difference. While in
          many cases, the price difference could be minor, the lack of
          transparency is troubling - there's no guarantee that you're paying a
          fair price.
        </li>
        <li>
          It's unclear that Robinhood has the proper security protocals in place.
          There have been several accounts of Robinhood users being hacked and
          having their accounts drained without Robinhood properly covering the
          loss (as they promise).
        </li>
      </ul>
      <p>
        Based on these reasons, I would only choose Robinhood if your initial
        investment is well under $1,000, say in the $100-$200 range. Once your
        investments reach $1,000, I would recommend that you open a new account
        with a traditional broker, perhaps keeping both accounts open and using
        Robinhood only for small investments.
      </p>
      <h2>Recommended Broker: Charles Schwab</h2>
      <p>
        I've been a long-time client with Charles Schwab and have overall been
        very happy with their services. Some of the reasons why I recommend them
        include:
      </p>
      <ul>
        <li>
          Good Online and In-Person Support: While most of the business can be
          handled online, it is sometimes very helpful to have access to branches
          to resolve any complex situations.
        </li>
        <li>
          High Security: The online platform has proper security controls such as
          limits on transfers out of the account and high risk trading activities.
        </li>
        <li>
          Banking Features: Checking, debit cards, wire transfers are all part of
          their core features, enabling them to act in a very similar manner to a
          typical bank. This often blurs the tough decision on whether to keep
          money need in the short term in an investment account or a bank.
        </li>
        <li>
          Expertise in Complex Transactions: They can help with complex
          transactions such as rolling over 401K or retirement accounts, settling
          estates, and other financial activities.
        </li>
        <li>
          Good Negotiated Rates with Mutual Funds: Schwab has agreements with most the 
          major mutual funds which allow them to waive all fees related to buying or 
          selling the funds.  This is done due to the volume of business which Schwab handles.
          Overall, this results in lower fees for the investor.
        </li>
      </ul>
      <h2>Summary</h2>
      <p>
        Hopefully this has been helpful in discussing some of the considerations
        around choosing a broker. I do think it makes sense to be strategic about
        the choice - while some of the initial aspects of the broker are fairly
        simple (accepting a deposit and buying a mutual fund), this can be an
        important long term relationship which has more complexities as your
        investments (and profits!) grow. Choose wisely and happy investing!
      </p>
    </>
  );
  
  export default ResBrokerChoice;