import Format from '../../Utility/Format';
import BootstrapTable from '../../Utility/BootstrapTable';

export default function AllAcounts({acctList, setAccountDetail}) {
    const dateFormatter = (val, row) => {
        return Format.FormatDate(val);
    }
    
    const percentFormatter = (val, row) => {
        return Format.FormatPercent(val);
    }

    const currencyFormatter = (val, row) => {
        return Format.FormatCurrency(val);
    }

    const getName = (val, row) => {
        return `${row.firstName} ${row.lastName}`;
    }

    const getGain = (val, row) => {
        return Format.FormatCurrency(row.value - row.cost);
    }

    const columns = [
        {field: 'firstName', title: 'Name', sortable:true, formatter: getName},
        {field: 'value', title: 'Value', sortable:true, formatter: currencyFormatter},
        {field: 'cost', title: 'Cost', sortable:true, formatter: currencyFormatter},
        {field: 'value', title: 'Gain', sortable:true, formatter: getGain},
        {field: 'percentGain', title: '% Gain', sortable:true, formatter: percentFormatter},
        {field: 'annualPercentGain', title: 'Annual % Gain', sortable:true, formatter: percentFormatter},
        {field: 'startDate', title: 'Start Date', sortable:true, formatter: dateFormatter},
    ];

    const data = acctList;

    return (
        <>
            <h5 style={{float: 'left', marginTop: 20}}>List of Accounts</h5>
            <BootstrapTable
                columns={columns}
                data={data}
                showColumns={true}
                fixedColumns={true}
                fixedNumber={1}
                resizable={true}
                showToggle={true} 
                search={false}
                classes={'table-striped table-bordered table'}
                theadClasses={'thead-light'}
            />
        </>
    );
}