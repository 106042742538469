import React, {useState} from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import AllAccounts from './AllAccounts.js';
import AccountTabSummary from './AccountTabSummary';
import AccountTabPerformance from './AccountTabPerformance';
import AccountTabChart from './AccountTabChart';

export default function AccountDetail({acctList, setAccountDetail})
{
    const [tabKey, setTabKey] = useState('all_accounts');

    const showAccountDetail = (acct) => {
        if (tabKey === 'all_accounts')
            setTabKey('summary');
        setAccountDetail(acct);
    }

    return (
        <div>
            <Tabs id="accountTabs" activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                <Tab eventKey="all_accounts" title="All Accounts">
                    <AllAccounts acctList={acctList} setAccountDetail={showAccountDetail}></AllAccounts>
                </Tab>
                <Tab eventKey="summary" title="Summary">
                    <AccountTabSummary></AccountTabSummary>
                </Tab>
                <Tab eventKey="performance" title="Performance">
                    <AccountTabPerformance></AccountTabPerformance>
                </Tab>
                <Tab eventKey="charts" title="Charts">
                    <AccountTabChart></AccountTabChart>
                </Tab>
            </Tabs>
        </div>
    );
}
