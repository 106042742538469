import React, {useState, useEffect, useContext} from 'react';
import { StockApi } from '../../Services/StockApi';
import Format from '../../Utility/Format';
import { AppContext } from '../../AppContext';
import BootstrapTable from '../../Utility/BootstrapTable';

export default function AccountTabPerformance() 
{
    const [choiceList, setChoiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { acctInfo } = useContext(AppContext);

    useEffect(() => {
        const getData = async () => {
            try {
                if (!acctInfo)
                    return;

                setLoading(true);
                var stockApi = new StockApi()
                var choiceData = await stockApi.getAccountChoices(acctInfo.accountId);
                choiceData.sort((b, a) => a.percentGain - b.percentGain);
                setChoiceList(choiceData);
            }
            catch (e) {
                setError(e);
            }
            finally {
                setLoading(false);
            }
        }
        getData();
    }, [acctInfo])

    function GetStatus()
    {
        if (loading)
            return <p>Data Loading...</p>;
        else if (error)
            return <p>{"Error: " + error}</p>;
        else if (!acctInfo)
            return <p>Please select account to show information.</p>
        return <></>;
    }

    function Choices()
    {
        const percentFormatter = (val, row) => {
            return Format.FormatPercent(val);
        }
    
        const currencyFormatter = (val, row) => {
            return Format.FormatCurrency(val);
        }

        const rowStyle = (row, index) => {
            if (row.chosen)
                return { classes: 'table-active'};
            return { classes: ''}
        }

        const columns = [
            {field: 'symbol', title: 'Symbol', sortable: true},
            {field: 'name', title: 'Name', sortable: true},
            {field: 'startPrice', title: 'Start Price', sortable: true, formatter: currencyFormatter},
            {field: 'currentPrice', title: 'Price', sortable: true, formatter: currencyFormatter},
            {field: 'percentGain', title: '% Gain', sortable: true, formatter: percentFormatter},
            {field: 'annualPercentGain', title: 'Annual % Gain', sortable: true, formatter: percentFormatter},
        ];

        const data = choiceList;

        return (
            <>
                <h5 style={{float: 'left', marginTop: 20}}>Performance of Choices</h5>
                <BootstrapTable
                    columns={columns}
                    data={data}
                    showColumns={true}
                    fixedColumns={true}
                    resizable={false}
                    showToggle={true} 
                    search={false}
                    classes={'table-bordered table'}
                    theadClasses={'thead-light'}
                    rowStyle={rowStyle}
                />
            </>
        )
    }

    function ShowSummary()
    {
        let percent = 0, annualPercent = 0, chosenPercent = 0, chosenAnnualPercent = 0, chosenNum = 0;

        for (var i = 0; i < choiceList.length; i++)
        {
            percent += choiceList[i].percentGain;
            annualPercent += choiceList[i].annualPercentGain;
            if (choiceList[i].chosen)
            {
                chosenPercent += choiceList[i].percentGain;
                chosenAnnualPercent += choiceList[i].annualPercentGain;
                chosenNum++;
            }
        }

        let avgPercent = percent / choiceList.length;
        let avgAnnualPercent = annualPercent / choiceList.length;
        let actualPercent = chosenPercent / chosenNum;
        let actualAnnualPercent = chosenAnnualPercent / chosenNum;
        return (
            <>
                <p>This table shows the performance of the selected stocks (highlighted) as compared to the initial set of choices.  Note: Dividends are not currently part of the reported results.</p>
                <p>The average expected percent gain was: <b>{Format.FormatPercent(avgPercent)}</b> (annual percent = {Format.FormatPercent(avgAnnualPercent)})</p>
                <p>Based on the choices, the actual percent gain has been: <b>{Format.FormatPercent(actualPercent)}</b> (annual percent = {Format.FormatPercent(actualAnnualPercent)})</p>
                {(actualPercent > avgPercent) && <p><b>Great Job!</b></p>}
            </>
        )
    }

    return (
        <>
            <GetStatus></GetStatus>
            {acctInfo && <Choices></Choices>}
            {acctInfo && <ShowSummary></ShowSummary>}
        </>
    );
}