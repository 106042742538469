
export class StockApi {
    constructor()
    {
        this.hostURL = sessionStorage.getItem('hostURL');
        if (!this.hostURL)
        {
            // const requestHost = async () => {
            //     const response = await fetch('environment.json');
            //     const json = await response.json();

            //     this.hostURL = json.hostURL
            //     sessionStorage.setItem('hostURL', this.hostURL);
            // }

            // requestHost();

            this.hostURL = "https://welchstockapi.azurewebsites.net";
            sessionStorage.setItem('hostURL', this.hostURL);
        }
    }

    async genericGet(getUrl) {
        const url = `${this.hostURL}${getUrl}`;

        return fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("HTTP error, status = " + response.status);
            }
            return response.json();
        })
        .then(json => { return json; })
    }

    async genericPost(postUrl, body) {
        const url = `${this.hostURL}${postUrl}`;

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("HTTP error, status = " + response.status);
            }
            return response.json();
        })
        .then(json => { return json; })
    }

    async getAccountSummary(acctNum) {
        return this.genericGet(`/api/account/${acctNum}/summary`);
    }

    async getAccountPositions(acctNum) {
        return this.genericGet(`/api/account/${acctNum}/positions`);
    }

    async getAllAccounts() {
        return this.genericGet(`/api/account`);
    }

    async getAccountChoices(acctNum) {
        return this.genericGet(`/api/account/${acctNum}/choices`);
    }

    async getHistoricalDataForAccount(acctNum) {
        return this.genericGet(`/api/account/${acctNum}/history`);
    }
}

